import React from "react";

export default function Other() {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Other" clip-path="url(#clip0)">
        <g id="Group">
          <path
            id="Vector"
            d="M64 320C28.7147 320 0 291.285 0 256C0 220.715 28.7147 192 64 192C99.2853 192 128 220.715 128 256C128 291.285 99.2853 320 64 320ZM64 213.333C40.4693 213.333 21.3333 232.469 21.3333 256C21.3333 279.531 40.4693 298.667 64 298.667C87.5307 298.667 106.667 279.531 106.667 256C106.667 232.469 87.5307 213.333 64 213.333Z"
            fill="#242424"
          />
        </g>
        <g id="Group_2">
          <path
            id="Vector_2"
            d="M256 320C220.715 320 192 291.285 192 256C192 220.715 220.715 192 256 192C291.285 192 320 220.715 320 256C320 291.285 291.285 320 256 320ZM256 213.333C232.469 213.333 213.333 232.469 213.333 256C213.333 279.531 232.469 298.667 256 298.667C279.531 298.667 298.667 279.531 298.667 256C298.667 232.469 279.531 213.333 256 213.333Z"
            fill="#242424"
          />
        </g>
        <g id="Group_3">
          <path
            id="Vector_3"
            d="M448 320C412.715 320 384 291.285 384 256C384 220.715 412.715 192 448 192C483.285 192 512 220.715 512 256C512 291.285 483.285 320 448 320ZM448 213.333C424.469 213.333 405.333 232.469 405.333 256C405.333 279.531 424.469 298.667 448 298.667C471.531 298.667 490.667 279.531 490.667 256C490.667 232.469 471.531 213.333 448 213.333Z"
            fill="#242424"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="512" height="512" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
