import React from "react";

export default function Truck() {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Truck" clip-path="url(#clip0)">
        <g id="Group">
          <path
            id="Vector"
            d="M504.65 432.557H304.65V397.072C304.65 387.686 289.65 387.259 289.65 397.072V432.557H245.648C242.259 395.558 211.046 366.478 173.149 366.478C135.252 366.478 104.039 395.558 100.65 432.557H90.179C90.683 430.775 90.972 428.903 90.972 426.961V387.893C90.972 376.536 81.733 367.296 70.376 367.296H35.15V353.683H49.477C62.341 353.683 74.285 346.205 79.904 334.634L87.543 318.905C92.215 309.282 84.907 297.5 74.142 297.5H35.15C35.15 293.023 35.06 288.533 35.173 284.057H149.838C180.613 284.057 205.651 259.02 205.651 228.245V110.557H289.651V354.184C289.651 363.57 304.651 363.997 304.651 354.184V15H504.651C514.037 15 514.464 0 504.651 0H297.151C293.009 0 289.651 3.357 289.651 7.5V52.5H182.642C161.732 52.5 143.384 65.875 136.985 85.783L133.844 95.556C105.449 94.918 78.937 111.949 69.209 138.971C65.565 149.092 80.258 152.562 83.322 144.051C90.534 124.017 109.687 110.556 130.98 110.556H190.649V228.244C190.649 250.748 172.34 269.056 149.836 269.056H38.321L69.283 183.05C72.462 174.22 58.492 168.737 55.17 177.97L23.492 265.964C21.274 272.125 20.15 278.568 20.15 285.114V367.325C9.07002 367.644 0.150024 376.735 0.150024 387.891V426.959C0.150024 438.315 9.38902 447.555 20.746 447.555H100.82C104.966 483.772 135.813 512 173.149 512C210.485 512 241.332 483.772 245.477 447.557H297.149H504.649C514.035 447.557 514.463 432.557 504.65 432.557V432.557ZM35.635 312.5L73.977 312.501L66.41 328.081C63.283 334.521 56.636 338.683 49.476 338.683H35.635V312.5ZM149.6 95.557L151.266 90.373C155.663 76.692 168.272 67.5 182.642 67.5H289.65V95.557H198.15H149.6ZM15.15 426.961V387.893C15.15 384.807 17.66 382.296 20.746 382.296H70.376C73.462 382.296 75.972 384.807 75.972 387.893V426.961C75.972 430.047 73.462 432.557 70.376 432.557H20.746C17.66 432.557 15.15 430.047 15.15 426.961V426.961ZM173.149 497C141.268 497 115.33 471.089 115.33 439.239C115.33 407.389 141.268 381.477 173.149 381.477C205.03 381.477 230.967 407.389 230.967 439.239C230.967 471.089 205.031 497 173.149 497Z"
            fill="#242424"
          />
          <path
            id="Vector_2"
            d="M173.149 397.931C150.355 397.931 131.811 416.462 131.811 439.24C131.811 462.018 150.355 480.548 173.149 480.548C195.943 480.548 214.487 462.018 214.487 439.24C214.487 416.462 195.943 397.931 173.149 397.931V397.931ZM173.149 465.547C158.626 465.547 146.811 453.745 146.811 439.239C146.811 424.732 158.626 412.93 173.149 412.93C187.672 412.93 199.487 424.732 199.487 439.239C199.487 453.745 187.672 465.547 173.149 465.547Z"
            fill="#242424"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="512" height="512" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
