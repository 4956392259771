import React, { useContext } from "react";
import { QuestionSection, Radio } from "../../components/utility/quote";
import { navigate } from "gatsby-link";

import NavigationContext from "../../contexts/navigationContext";
import Car from "../../svgs/navigation/Car";
import Bike from "../../svgs/navigation/Bike";
import Truck from "../../svgs/navigation/Truck";
import Other from "../../svgs/navigation/Other";

const Next = "/navigation/Questions/3";
const Points = [
  {
    question: "What type of vehicle need GPS tracking?",
    progress: "45%",
    options: [
      {
        answer: "Car",
        to: Next,
        icon: <Car />,
      },
      { answer: "Truck", to: Next, icon: <Truck /> },
      { answer: "Bike", to: Next, icon: <Bike /> },
      { answer: "Other", to: Next, icon: <Other /> },
    ],
  },
];
export default function Question2({ title }) {
  const { que2, setQue2 } = useContext(NavigationContext);

  return Points.map((item) => {
    return (
      <QuestionSection
        title={title}
        question={item.question}
        progress={item.progress}
      >
        {item.options.map((items) => {
          return (
            <Radio
              key={items.answer}
              icon={items.icon}
              value={items.answer}
              title={items.answer}
              isSelected={que2 === items.answer}
              onChange={(e) => {
                setQue2(e.target.value);
                navigate(items.to);
              }}
            />
          );
        })}
      </QuestionSection>
    );
  });
}
